import { axiosInst } from './index';

//일반회원 가입
function registerUser(userData) {
	return axiosInst.post('v1/users/signup', userData);
}

//일반 로그인
function signinUser(userData) {
	return axiosInst.post('v1/users/login', userData);
}

//비회원 가입
function registerNoUser(userData) {
	return axiosInst.post('v1/nonusers/signup', userData);
}

//비회원 로그인
function signinNoUser(userData) {
	return axiosInst.post('v1/nonusers/login', userData);
}

//회원 정보 가져오기
function getUser(userId, userType) {
	return axiosInst.get('v1/users/getUsersInfo', {
		params: { userId, userType },
	});
}

//회원 정보 수정
function modifyUser(userData) {
	return axiosInst.post('v1/users/modified', userData);
}

//일반 사용자 조회
function getUsers(keyword, page, pageSize) {
	return axiosInst.get(`v1/users`, {
		params: { keyword, page, pageSize },
	});
}

//기업회원 사용자 조회
function getBizUsers(keyword, page, pageSize) {
	return axiosInst.get(`v1/biz-users`, {
		params: { keyword, page, pageSize },
	});
}

//관리자 조회
function getAdminUsers(keyword, page, pageSize) {
	return axiosInst.get(`v1/admin-users`, {
		params: { keyword, page, pageSize },
	});
}

//회원 조회
function getOnceUser(userId) {
	return axiosInst.get(`v1/users/select/${userId}`);
}

//회원수정
function updateUser(userData) {
	return axiosInst.post('v1/users/modified', userData);
}

//관리자추가
function createAdmin(adminData) {
	return axiosInst.post('v1/admin', adminData);
}
//관리자 로그인
function signinAdmin(adminData) {
	return axiosInst.post('v1/admin/login', adminData);
}

//qna 삭제
function userQnaList(userId) {
	return axiosInst.get(`/v1/users/${userId}/qna`);
}

export {
	registerUser,
	signinUser,
	registerNoUser,
	signinNoUser,
	getUser,
	modifyUser,
	getAdminUsers,
	getBizUsers,
	getUsers,
	getOnceUser,
	updateUser,
	createAdmin,
	signinAdmin,
	userQnaList,
};
