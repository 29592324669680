import Vue from 'vue';
import Vuex from 'vuex';
import {
	saveAuthToCookie,
	saveAdminToCookie,
	getAuthFromCookie,
	getAdminFromCookie,
} from '@/utils/cookies';

import { signinAdmin } from '@/api/users';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		userId: getAdminFromCookie() || '',
		token: getAuthFromCookie() || '',
	},
	getters: {
		isLogin(state) {
			return state.userId !== '';
		},
		isLoginToken(state) {
			return state.token !== '';
		},
	},
	mutations: {
		setUserId(state, userId) {
			state.userId = userId;
		},
		clearUserId(state) {
			state.userId = '';
		},
		setToken(state, token) {
			state.token = token;
		},
	},
	actions: {
		async SIGNIN_USER({ commit }, userData) {
			const { data } = await signinAdmin(userData);
			if (data.success === 'false') return data.success;
			const token = data.token;
			commit('setToken', token);
			commit('setUserId', data.users.userId);
			saveAuthToCookie(token);
			saveAdminToCookie(data.users.userId);
			return data.success;
		},
	},
});
