function saveAuthToCookie(value) {
	document.cookie = `gonggu_admin_auth=${value}; max-age=3600;`;
}

function saveAdminToCookie(value) {
	document.cookie = `gonggu_admin=${value}; max-age=3600;`;
}

function getAuthFromCookie() {
	return document.cookie.replace(
		/(?:(?:^|.*;\s*)gonggu_admin_auth\s*=\s*([^;]*).*$)|^.*$/,
		'$1',
	);
}

function getAdminFromCookie() {
	return document.cookie.replace(
		/(?:(?:^|.*;\s*)gonggu_admin\s*=\s*([^;]*).*$)|^.*$/,
		'$1',
	);
}

function deleteCookie(value) {
	document.cookie = `${value}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export {
	saveAuthToCookie,
	saveAdminToCookie,
	getAuthFromCookie,
	getAdminFromCookie,
	deleteCookie,
};
