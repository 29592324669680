import moment from 'moment';

const currencyStamp = price => {
	const num = new Number(price);
	return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,');
};

const formatDate = dt => {
	return moment(String(dt)).format('YYYY-MM-DD');
};

const fullFormatDate = dt => {
	return moment(String(dt)).format('YYYY-MM-DD HH:mm');
};

const phoneNumberHyphen = phoneNumber => {
	if (!phoneNumber) return phoneNumber;

	phoneNumber = phoneNumber.replace(/[^0-9]/g, '');

	let tmp = '';
	if (phoneNumber.length < 4) {
		return phoneNumber;
	} else if (phoneNumber.length < 7) {
		tmp += phoneNumber.substr(0, 3);
		tmp += '-';
		tmp += phoneNumber.substr(3);
		return tmp;
	} else if (phoneNumber.length == 8) {
		tmp += phoneNumber.substr(0, 4);
		tmp += '-';
		tmp += phoneNumber.substr(4);
		return tmp;
	} else if (phoneNumber.length < 10) {
		if (phoneNumber.substr(0, 2) == '02') {
			//02-123-5678
			tmp += phoneNumber.substr(0, 2);
			tmp += '-';
			tmp += phoneNumber.substr(2, 3);
			tmp += '-';
			tmp += phoneNumber.substr(5);
			return tmp;
		}
	} else if (phoneNumber.length < 11) {
		if (phoneNumber.substr(0, 2) == '02') {
			//02-1234-5678
			tmp += phoneNumber.substr(0, 2);
			tmp += '-';
			tmp += phoneNumber.substr(2, 4);
			tmp += '-';
			tmp += phoneNumber.substr(6);
			return tmp;
		} else {
			//010-123-4567
			tmp += phoneNumber.substr(0, 3);
			tmp += '-';
			tmp += phoneNumber.substr(3, 3);
			tmp += '-';
			tmp += phoneNumber.substr(6);
			return tmp;
		}
	} else {
		//010-1234-5678
		tmp += phoneNumber.substr(0, 3);
		tmp += '-';
		tmp += phoneNumber.substr(3, 4);
		tmp += '-';
		tmp += phoneNumber.substr(7);
		return tmp;
	}
};

const orderStatus = status => {
	if (!status) return '';
	let statusName = '';

	switch (status) {
		case 'CANCELED':
			statusName = '취소완료';
			break;
		case 'CANCEL REQUESTED':
			statusName = '취소요청';
			break;
		case 'ORDERED':
			statusName = '주문완료';
			break;
		case 'PAYMENT PENDING':
			statusName = '입금대기';
			break;
		case 'PAYMENT RECEIVED':
			statusName = '입금완료';
			break;
		case 'PAYMENT COMPLETED':
			statusName = '결제완료';
			break;
		case 'SHIPPED':
			statusName = '배송중';
			break;
		case 'DELIVERED':
			statusName = '배송완료';
			break;
		case 'EXCHANGE REQUESTED':
			statusName = '교환요청';
			break;
		case 'RETURN REQUESTED':
			statusName = '반품요청';
			break;
		case 'RETURNED':
			statusName = '반품완료';
			break;
		default:
			statusName = '';
			break;
	}

	return statusName;
};

const deliveryStauts = status => {
	if (!status) return '';
	let statusName = '';

	switch (status) {
		case 'PRE':
			statusName = '배송 준비중';
			break;
		case 'SHIP':
			statusName = '배송중';
			break;
		case 'COMPL':
			statusName = '배송완료';
			break;
		case 'CANCEL':
			statusName = '배송취소';
			break;
		default:
			statusName = '';
			break;
	}

	return statusName;
};

const paymentType = type => {
	if (!type) return '';
	let typeName = '';

	switch (type) {
		case 'CREDIT':
			typeName = '신용카드';
			break;
		case 'CASH':
			typeName = '무통장입금';
			break;
		default:
			typeName = '';
			break;
	}

	return typeName;
};

export {
	currencyStamp,
	formatDate,
	fullFormatDate,
	phoneNumberHyphen,
	orderStatus,
	deliveryStauts,
	paymentType,
};
