<template>
	<section class="body" v-if="isUserLogin">
		<!-- start: header -->
		<header class="header header-nav-menu header-nav-links">
			<div class="logo-container">
				<a href="../" class="logo">
					<img
						src="/assets/img/logo-light.png"
						class="logo-image-mobile"
						width="90"
						height="41"
						alt="Porto Admin"
					/>
				</a>
				<button
					class="btn header-btn-collapse-nav d-lg-none"
					data-bs-toggle="collapse"
					data-bs-target=".header-nav"
				>
					<i class="fas fa-bars"></i>
				</button>
			</div>

			<!-- start: search & user box -->
			<div class="header-right">
				<div id="userbox" class="userbox">
					<a href="#" data-bs-toggle="dropdown">
						<span class="profile-picture profile-picture-as-text">AD</span>
						<div
							class="profile-info profile-info-no-role"
							data-lock-name="John Doe"
							data-lock-email="johndoe@okler.com"
						>
							<span class="name"
								>안녕하세요,
								<strong class="font-weight-semibold"
									>해오름 관리자님.</strong
								></span
							>
						</div>

						<i class="fas fa-chevron-down text-color-dark"></i>
					</a>

					<div class="dropdown-menu">
						<ul class="list-unstyled">
							<!-- <li>
								<a role="menuitem" tabindex="-1" href="#"
									><i class="bx bx-user"></i> My Profile</a
								>
							</li>
							<li>
								<a
									role="menuitem"
									tabindex="-1"
									href="#"
									data-lock-screen="true"
									><i class="bx bx-lock-open-alt"></i> Lock Screen</a
								>
							</li> -->
							<li>
								<a
									role="menuitem"
									tabindex="-1"
									href="#"
									@click.prevent="logout"
									><i class="bx bx-log-out"></i> 로그아웃</a
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- end: search & user box -->
		</header>
		<!-- end: header -->

		<div class="inner-wrapper">
			<aside id="sidebar-left" class="sidebar-left">
				<div class="sidebar-header">
					<div
						class="sidebar-toggle d-none d-md-flex"
						data-toggle-class="sidebar-left-collapsed"
						data-target="html"
						data-fire-event="sidebar-left-toggle"
					>
						<i class="fas fa-bars" aria-label="Toggle sidebar"></i>
					</div>
				</div>

				<div class="nano">
					<div class="nano-content">
						<nav id="menu" class="nav-main" role="navigation">
							<ul class="nav nav-main">
								<li>
									<router-link to="/">
										<i class="bx bx-home-alt" aria-hidden="true"></i>
										<span>대쉬보드</span>
									</router-link>
								</li>
								<li class="nav-parent nav-expanded nav-active">
									<a class="nav-link" href="#">
										<i class="bx bx-user" aria-hidden="true"></i>
										<span>회원</span>
									</a>
									<ul class="nav nav-children">
										<li>
											<a class="nav-link" href="/member">회원 관리</a>
											<!-- <router-link to="/member">- 회원 관리</router-link> -->

											<!-- </a> -->
										</li>
										<li>
											<a class="nav-link" href="/biz-member">기업회원 관리</a>
											<!-- <router-link to="/member">- 회원 관리</router-link> -->

											<!-- </a> -->
										</li>
										<li>
											<a class="nav-link" href="/admin">관리자 관리</a>
											<!-- <router-link to="/admin"> - 관리자 관리 </router-link> -->
										</li>
									</ul>
								</li>
								<li class="nav-parent nav-expanded nav-active">
									<a class="nav-link" href="#">
										<i class="bx bx-cart-alt" aria-hidden="true"></i>
										<span>상품</span>
									</a>
									<ul class="nav nav-children">
										<li>
											<a class="nav-link" href="/product"> - 상품 관리 </a>
										</li>
										<li>
											<a class="nav-link" href="/product/add"> - 상품 등록 </a>
										</li>
										<li>
											<a class="nav-link" href="/category"> - 카테고리 관리 </a>
										</li>
										<!-- <li>
											<a class="nav-link" href="/category/add">
												- 카테고리 등록
											</a>
										</li> -->
										<li>
											<a class="nav-link" href="/order"> - 주문 관리 </a>
										</li>
										<!-- <li>
											<a class="nav-link" href="/coupon">
												- 쿠폰/기프트콘 관리
											</a>
										</li> -->
										<!-- <li>
											<a class="nav-link" href="/coupon/add">
												- 쿠폰/기프트콘 등록
											</a>
										</li> -->
									</ul>
								</li>
								<li class="nav-parent nav-expanded nav-active">
									<a class="nav-link" href="#">
										<i class="bx bx-clipboard" aria-hidden="true"></i>
										<span>매장관리</span>
									</a>
									<ul class="nav nav-children">
										<li>
											<a class="nav-link" href="/company-info"> - 회사정보 </a>
										</li>
										<li>
											<a class="nav-link" href="/account-info"> - 계좌정보 </a>
										</li>
										<li>
											<a class="nav-link" href="/board">
												- 게시판 관리(자유게시판)
											</a>
										</li>
										<li>
											<a class="nav-link" href="/adboard">
												- 관리자 전용 게시판(공지사항)
											</a>
										</li>
										<li>
											<a class="nav-link" href="/refboard"> - 자료실 게시판 </a>
										</li>
										<li>
											<a class="nav-link" href="/business">
												- 거래처(사업자) 관리
											</a>
										</li>
										<!-- <li>
											<a class="nav-link" href="/vote"> - 투표 관리 </a>
										</li> -->
										<li>
											<a class="nav-link" href="/qna"> - Q&A </a>
										</li>
										<!-- <li>
											<a class="nav-link" href="/mail"> - 메일 </a>
										</li> -->
									</ul>
								</li>
								<li class="nav-parent nav-expanded nav-active">
									<a class="nav-link" href="#">
										<i class="bx bx-chart" aria-hidden="true"></i>
										<span>관리(시스템, 통계)</span>
									</a>
									<ul class="nav nav-children">
										<li>
											<a class="nav-link" href="/log"> - 호스트 로그 </a>
										</li>
										<li>
											<a class="nav-link" href="/analytice"> - 통계 </a>
										</li>
									</ul>
								</li>
								<li class="nav-parent nav-expanded nav-active">
									<a class="nav-link" href="#">
										<i class="bx bx-reset" aria-hidden="true"></i>
										<span>환경 설정</span>
									</a>
									<ul class="nav nav-children">
										<li>
											<a class="nav-link" href="/banner"> - 배너 설정 </a>
										</li>
										<li>
											<a class="nav-link" href="/setting">
												- 메인 상품 리스트 설정
											</a>
										</li>
										<!-- <li>
											<a class="nav-link" href="/env"> - 기타 설정 </a>
										</li> -->
									</ul>
								</li>
							</ul>
						</nav>

						<hr class="separator" />
					</div>

					<!-- <script>
						// Maintain Scroll Position
						if (typeof localStorage !== 'undefined') {
							if (localStorage.getItem('sidebar-left-position') !== null) {
								var initialPosition = localStorage.getItem(
										'sidebar-left-position',
									),
									sidebarLeft = document.querySelector(
										'#sidebar-left .nano-content',
									);

								sidebarLeft.scrollTop = initialPosition;
							}
						}
					</script> -->
				</div>
			</aside>

			<!-- <router-link to="/index"></router-link>
			<router-link to="/user"></router-link> -->
			<router-view></router-view>
		</div>
	</section>
	<section class="body-sign" v-else>
		<div class="center-sign">
			<a href="/" class="logo float-start">
				<img src="/assets/img/logo.png" height="70" alt="학공" />
			</a>

			<div class="panel card-sign">
				<div class="card-title-sign mt-3 text-end">
					<h2 class="title text-uppercase font-weight-bold m-0">
						<i
							class="bx bx-user-circle me-1 text-6 position-relative top-5"
						></i>
						로그인
					</h2>
				</div>
				<div class="card-body">
					<form action="index.html" method="post">
						<div class="form-group mb-3">
							<label>관리자 아이디</label>
							<div class="input-group">
								<input
									name="username"
									type="text"
									class="form-control form-control-lg"
									v-model="userId"
								/>
								<span class="input-group-text">
									<i class="bx bx-user text-4"></i>
								</span>
							</div>
						</div>

						<div class="form-group mb-3">
							<div class="clearfix">
								<label class="float-start">패스워드</label>
								<!-- <a href="pages-recover-password.html" class="float-end"
									>Lost Password?</a
								> -->
							</div>
							<div class="input-group">
								<input
									name="pwd"
									type="password"
									class="form-control form-control-lg"
									v-model="pwd"
								/>
								<span class="input-group-text">
									<i class="bx bx-lock text-4"></i>
								</span>
							</div>
						</div>

						<div class="row">
							<div class="col-sm-8">
								<div class="checkbox-custom checkbox-default">
									<input
										id="RememberMe"
										name="rememberme"
										type="checkbox"
										v-model="remember"
									/>
									<label for="RememberMe">기억하기</label>
								</div>
							</div>
							<div class="col-sm-4 text-end">
								<button
									type="submit"
									class="btn btn-primary mt-2"
									@click.prevent="trySignIn"
								>
									로그인
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>

			<p class="text-center text-muted mt-3 mb-3">
				&copy; Copyright (C) 2014 HaeorumCommunications CO.LTD. <br />
				All rightsreserved.
			</p>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			isAdmin: false,
			userId: '',
			pwd: '',
			remember: false,
		};
	},
	created() {
		this.isRemember();
	},
	computed: {
		isUserLogin() {
			return this.$store.getters.isLoginToken;
		},
	},
	methods: {
		isRemember() {
			const isRemember = this.$cookies.get('gonggu_remember_a');
			const admin = this.$cookies.get('gonggu_admin');
			// const auth = this.$cookies.get('gonggu_admin_auth');

			if (isRemember === 'true') {
				this.userId = admin;
				this.remember = true;
				return;
			}
			this.userId = '';
		},
		async trySignIn() {
			try {
				if (!this.userId) {
					alert('아이디를 입력 해 주세요.');
					return;
				}

				if (!this.pwd) {
					alert('비밀번호를 입력 해 주세요.');
					return;
				}

				const userDt = {
					userId: this.userId,
					pwd: this.pwd,
				};

				this.$cookies.remove('gonggu_admin_auth');
				const isLogin = await this.$store.dispatch('SIGNIN_USER', userDt);

				if (isLogin === 'false' || !isLogin) {
					alert('관리자 ID가 없습니다. 다시 로그인 해 주세요.');
					return;
				}

				this.$cookies.set('gonggu_remember_a', this.remember);
				this.$cookies.set('gonggu_admin', this.userId);

				this.isAdmin = true;
			} catch (error) {
				alert('관리자 ID가 없습니다. 다시 로그인 해 주세요.');
				console.log(error);
			}
		},
		logout() {
			this.$cookies.remove('gonggu_admin_auth');
			this.isAdmin = false;
			location.href = '/';
		},
	},
};
</script>

<style>
/* @import '@/assets/css/preloader.css';
@import '@/assets/css/bootstrap.css';
@import '@/assets/css/meanmenu.css';
@import '@/assets/css/animate.css';
@import '@/assets/css/owl-carousel.css';
@import '@/assets/css/swiper-bundle.css';
@import '@/assets/css/backtotop.css';
@import '@/assets/css/magnific-popup.css';
@import '@/assets/css/nice-select.css';
@import '@/assets/flaticon/flaticon.css';
@import '@/assets/css/font-awesome-pro.css';
@import '@/assets/css/default.css';
@import '@/assets/css/style.css'; */
</style>
