import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			redirect: '/index',
		},
		//메인 페이지
		{
			path: '/index',
			component: () => import('@/views/IndexPage.vue'),
		},
		//사용자 리스트 페이지
		{
			path: '/member',
			component: () => import('@/views/MemberListPage.vue'),
		},
		//사용자 정보 및 수정 페이지
		{
			path: '/member/:id',
			component: () => import('@/views/MemberFormPage.vue'),
		},
		//기업사용자 리스트 페이지
		{
			path: '/biz-member',
			component: () => import('@/views/BizMemberListPage.vue'),
		},
		//기업사용자 정보 및 수정 페이지
		{
			path: '/biz-member/:id',
			component: () => import('@/views/BizMemberFormPage.vue'),
		},
		//관리자 리스트 페이지
		{
			path: '/admin',
			component: () => import('@/views/AdminListPage.vue'),
		},
		//관리자 정보 및 수정 페이지
		{
			path: '/admin/:id',
			component: () => import('@/views/AdminFormPage.vue'),
		},
		//관리자 추가 페이지
		{
			path: '/admin/0/new',
			component: () => import('@/views/AdminNewFormPage.vue'),
		},
		//상품 관리 페이지
		{
			path: '/product',
			component: () => import('@/views/ProductListPage.vue'),
		},
		//상품 추가 페이지
		{
			path: '/product/add',
			component: () => import('@/views/ProductNewFormPage.vue'),
		},
		//상품 일괄 페이지
		{
			path: '/product/batch',
			component: () => import('@/views/ProductBatchFormPage.vue'),
		},
		//상품 수정 페이지
		{
			path: '/product/:id',
			component: () => import('@/views/ProductFormPage.vue'),
		},
		//카테고리 리스트 페이지
		{
			path: '/category',
			component: () => import('@/views/CategoryListPage.vue'),
		},
		//카테고리 리스트 페이지
		{
			path: '/category/add',
			component: () => import('@/views/CategoryNewFormPage.vue'),
		},
		//카테고리 리스트 페이지
		{
			path: '/category/modify/:id',
			component: () => import('@/views/CategoryFormPage.vue'),
		},
		//주문 관리 페이지
		{
			path: '/order',
			component: () => import('@/views/OrderListPage.vue'),
		},
		//주문 상세 페이지
		{
			path: '/order/:id',
			component: () => import('@/views/OrderDetailPage.vue'),
		},
		//쿠폰/기프트콘 리스트 페이지
		{
			path: '/coupon',
			component: () => import('@/views/CouponListPage.vue'),
		},
		//쿠폰/기프트콘 리스트 페이지
		{
			path: '/coupon/add',
			component: () => import('@/views/CouponFormPage.vue'),
		},
		//메일 리스트
		{
			path: '/mail',
			component: () => import('@/views/MailListPage.vue'),
		},
		//메일 폼
		{
			path: '/main/form',
			component: () => import('@/views/MailFormPage.vue'),
		},
		//통계
		{
			path: '/analytice',
			component: () => import('@/views/AnalyticePage.vue'),
		},
		//배너 리스트
		{
			path: '/banner',
			component: () => import('@/views/BannerListPage.vue'),
		},
		//회사 정보
		{
			path: '/company-info',
			component: () => import('@/views/CompanyInfoPage.vue'),
		},
		//계좌 정보
		{
			path: '/account-info',
			component: () => import('@/views/AccountInfoPage.vue'),
		},
		//일반 게시판 리스트
		{
			path: '/board',
			component: () => import('@/views/BoardListPage.vue'),
		},
		//일반 게시판 수정
		{
			path: '/board/:id',
			component: () => import('@/views/BoardFormPage.vue'),
		},
		//관리자 게시판 리스트
		{
			path: '/adboard',
			component: () => import('@/views/BoardAdminListPage.vue'),
		},
		//관리자 게시판 수정
		{
			path: '/adboard/:id',
			component: () => import('@/views/BoardAdminFormPage.vue'),
		},
		//자료실 게시판 리스트
		{
			path: '/refboard',
			component: () => import('@/views/BoardRefListPage.vue'),
		},
		//자료실 게시판 수정
		{
			path: '/refboard/:id',
			component: () => import('@/views/BoardRefFormPage.vue'),
		},
		//투표 리스트
		{
			path: '/vote',
			component: () => import('@/views/VoteListPage.vue'),
		},
		//투표 수정
		{
			path: '/vote/:id',
			component: () => import('@/views/VoteFormPage.vue'),
		},
		//QA리스트
		{
			path: '/qna',
			component: () => import('@/views/QnaListPage.vue'),
		},
		//QA상세
		{
			path: '/qna/:id',
			component: () => import('@/views/QnaFormPage.vue'),
		},
		//사업자 리스트
		{
			path: '/business',
			component: () => import('@/views/BusinessListPage.vue'),
		},
		//사업자 상세 조회
		{
			path: '/business/:id',
			component: () => import('@/views/BusinessFormPage.vue'),
		},
		//로그 페이지
		{
			path: '/log',
			component: () => import('@/views/LogListPage.vue'),
		},
		//메인화면 리스트 세팅 페이지
		{
			path: '/setting',
			component: () => import('@/views/SettingPage.vue'),
		},
		//환경 세팅 페이지
		{
			path: '/env',
			component: () => import('@/views/EnvPage.vue'),
		},
		//에러 페이지
		{
			path: '*',
			component: () => import('@/views/404Page.vue'),
		},
	],
});
